import React from 'react';
import './File.css';

const File = (props) => {

  return (
    <div className="file__wrapper">
      <iframe src={ props.src } frameBorder="0" className="file__wrapper--iframe" title="file"></iframe>
    </div>
  );
};

export default File;
