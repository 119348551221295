import './App.css';
import {useContext} from 'react'
import { BrowserRouter, Routes, Route, Navigate} from 'react-router-dom';
import {UserContext} from './context/UserContext';
import Login from './components/Login/Login';
import Main from './components/Main/Main';
import History from './components/History/History';
import { ThemeProvider } from '@mui/material'
import { createTheme } from '@mui/material/styles'

function App() {

  const {user} = useContext(UserContext)

  const THEME = createTheme({
    typography: {
     "fontFamily": `"Montserrat", "Helvetica", "Arial", sans-serif`,
     "fontSize": 14,
     "fontWeightLight": 300,
     "fontWeightRegular": 400,
     "fontWeightMedium": 500
    }
  });

  return (
    <ThemeProvider theme={ THEME }>
      <BrowserRouter>
        <Routes>
          { user && (
            <>
              <Route path="/" element={<Main />} />
              <Route path="/main" element={<Main />} />
              <Route path="/history" element={<History />} />
            </>
          )}
          {!user && (
            <>
              <Route path="/login" element={<Login />} />
            </>
          )}
          <Route path="*" element={<Navigate to={user ? '/main':'/login'} />} />
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App;
