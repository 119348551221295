import React from 'react'
import { ListItemButton, ListItemText, Divider, ListItemIcon, ListItem } from '@mui/material'

const ExistingDocuments = (props) => {

    const [open, setOpen] = React.useState(false)

    const documents = props.documents.map( document => {
        return (
            <ListItem sx={{ py: 0 }}>
                <ListItemIcon sx={{ minWidth: 125 }}>{ document.DocType }</ListItemIcon>
                <ListItemText primary={ document.DocNum }></ListItemText>
            </ListItem>
        )
    })

    return (
        <>
            <ListItemButton sx={{ py: 0, mt: 2, pl: 0 }} onClick={() => setOpen(!open)}>
                <ListItemText>
                    <strong>Existing Documents ({ documents.length })</strong>
                </ListItemText>
            </ListItemButton>
            <Divider />
            { open && (
                documents
            )}
        </>
    )
}

export default ExistingDocuments