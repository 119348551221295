import React from 'react';
import { FormControl, Select, MenuItem, InputLabel } from '@mui/material'

const CarrierSelect = (props) => {

    const handleChange = (e) => {
        let selectedCarrier = e.target.value;
        let loadCosts = []
        let remittanceLocation = []
        let isPrimary = false
        let loadData = []
        let carrierName = null;

        for (let x = 0; x <= props.carriers.length; x++){
            if (props.carriers[x].code === selectedCarrier){
                loadCosts = props.carriers[x].loadCosts;
                remittanceLocation = props.carriers[x].remittanceLocation;
                isPrimary = props.carriers[x].isPrimary;
                loadData = props.loadData;
                carrierName = props.carriers[x].name;
                break;
            }
        }

        props.handleChange(selectedCarrier, loadCosts, remittanceLocation, isPrimary, loadData, carrierName)
    }

    return (
        <div className="load__form--group">
            <FormControl variant="standard" sx={{ minWidth: 300, mt: 1 }}>
                <InputLabel id="carrier">Carrier</InputLabel>
                <Select
                    labelId="carrier"
                    id="carrier"
                    label="Carrier"
                    required={ true }
                    name="carrier"
                    onChange={ handleChange }
                >
                    <MenuItem value="noselect" disabled>Select Carrier...</MenuItem>
                    { props.carriers.map( carrier => {
                        return (
                            <MenuItem value={ carrier.code } key={ carrier.code } >{ carrier.name }</MenuItem>
                        )
                    })}
                </Select>
            </FormControl>
        </div>
    );
};

export default CarrierSelect;
