import React from 'react';
import { FormControl, Input, InputLabel, Select, MenuItem } from '@mui/material'

const PageSelector = (props) => {

    const [selectedPages, setSelectedPages] = React.useState([]);

    const handlePageChange = (event) => {
      const {
        target: { value },
      } = event;
      setSelectedPages(
        typeof value === 'string' ? value.split(',') : value,
      );
    };

    const pages = Array.from(Array(props.pageCount).keys())

    return (
        <FormControl fullWidth variant="standard" className="flex1" sx={{ minWidth: 100 }}>

            { !props.hideLabel && (
                <InputLabel id={ props.id }>Page(s)</InputLabel>
            )}

            <Select

            id={ 'selector' + props.id }
            name={ props.name }
            multiple
            value={selectedPages}
            onChange={handlePageChange}
            input={<Input label="Name" />}
            required={props.required}
            >

            {pages.map((page) => (
                <MenuItem
                key={page + 1}
                value={page + 1}
                >
                {page + 1}
                </MenuItem>
            ))}

            </Select>
        </FormControl>
    );
};

export default PageSelector;