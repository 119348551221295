import './FreightBillHeader.css'
import React from 'react';
import PageSelector from '../PageSelector/PageSelector'
import { FormControl, TextField, Input, InputLabel, Select, MenuItem, Button, Dialog, DialogContent, DialogTitle, DialogContentText, DialogActions } from '@mui/material'
import { toUnitless } from '@mui/material/styles/cssUtils';

const FreightBillHeader = (props) => {

  const initialNewLocation = {
    'OrganizationName': 'Trademark_Ops',
    'LocationType': 'AdminOnly',
    'LocNum': '!!!NEWLOC!!!',
    'AddrName': null,
    'Addr1': null,
    'Addr2': '',
    'Addr3': '',
    'CityName': null,
    'StateCode': null,
    'PostalCode': null,
    'CountryISO2': 'US',
    'IsNew': true
  }

  const [selectedLocation, setSelectedLocation] = React.useState(getDefaultLocationCode());
  const [open, setOpen] = React.useState(false)
  const [newLocation, setNewLocation] = React.useState(initialNewLocation)
  const [locations, setLocations] = React.useState(props.locations)
  const [newLocFormHasErrors, setNewLocFormHasErrors] = React.useState(false)


  function getDefaultLocationCode(){
    if ( props === null ) return
    if ( props.locations.length === 0 ) return 
     for (let x = 0; x < props.locations.length; x++){
       if (props.locations[x].IsRemitTo === 'true' && props.locations[x].OrganizationName === 'Trademark_Ops'){
         return props.locations[x].LocNum
       }
     }
     return props.locations[0].LocNum;
  }

  const handleLocationChange = (e) => {
      setSelectedLocation(e.target.value)
  };

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClickClose = () => {
    setOpen(false)
  }

  var usStates = [
      { name: 'ALABAMA', abbreviation: 'AL'},
      { name: 'ALASKA', abbreviation: 'AK'},
      { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
      { name: 'ARIZONA', abbreviation: 'AZ'},
      { name: 'ARKANSAS', abbreviation: 'AR'},
      { name: 'CALIFORNIA', abbreviation: 'CA'},
      { name: 'COLORADO', abbreviation: 'CO'},
      { name: 'CONNECTICUT', abbreviation: 'CT'},
      { name: 'DELAWARE', abbreviation: 'DE'},
      { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
      { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
      { name: 'FLORIDA', abbreviation: 'FL'},
      { name: 'GEORGIA', abbreviation: 'GA'},
      { name: 'GUAM', abbreviation: 'GU'},
      { name: 'HAWAII', abbreviation: 'HI'},
      { name: 'IDAHO', abbreviation: 'ID'},
      { name: 'ILLINOIS', abbreviation: 'IL'},
      { name: 'INDIANA', abbreviation: 'IN'},
      { name: 'IOWA', abbreviation: 'IA'},
      { name: 'KANSAS', abbreviation: 'KS'},
      { name: 'KENTUCKY', abbreviation: 'KY'},
      { name: 'LOUISIANA', abbreviation: 'LA'},
      { name: 'MAINE', abbreviation: 'ME'},
      { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
      { name: 'MARYLAND', abbreviation: 'MD'},
      { name: 'MASSACHUSETTS', abbreviation: 'MA'},
      { name: 'MICHIGAN', abbreviation: 'MI'},
      { name: 'MINNESOTA', abbreviation: 'MN'},
      { name: 'MISSISSIPPI', abbreviation: 'MS'},
      { name: 'MISSOURI', abbreviation: 'MO'},
      { name: 'MONTANA', abbreviation: 'MT'},
      { name: 'NEBRASKA', abbreviation: 'NE'},
      { name: 'NEVADA', abbreviation: 'NV'},
      { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
      { name: 'NEW JERSEY', abbreviation: 'NJ'},
      { name: 'NEW MEXICO', abbreviation: 'NM'},
      { name: 'NEW YORK', abbreviation: 'NY'},
      { name: 'NORTH CAROLINA', abbreviation: 'NC'},
      { name: 'NORTH DAKOTA', abbreviation: 'ND'},
      { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
      { name: 'OHIO', abbreviation: 'OH'},
      { name: 'OKLAHOMA', abbreviation: 'OK'},
      { name: 'OREGON', abbreviation: 'OR'},
      { name: 'PALAU', abbreviation: 'PW'},
      { name: 'PENNSYLVANIA', abbreviation: 'PA'},
      { name: 'PUERTO RICO', abbreviation: 'PR'},
      { name: 'RHODE ISLAND', abbreviation: 'RI'},
      { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
      { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
      { name: 'TENNESSEE', abbreviation: 'TN'},
      { name: 'TEXAS', abbreviation: 'TX'},
      { name: 'UTAH', abbreviation: 'UT'},
      { name: 'VERMONT', abbreviation: 'VT'},
      { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
      { name: 'VIRGINIA', abbreviation: 'VA'},
      { name: 'WASHINGTON', abbreviation: 'WA'},
      { name: 'WEST VIRGINIA', abbreviation: 'WV'},
      { name: 'WISCONSIN', abbreviation: 'WI'},
      { name: 'WYOMING', abbreviation: 'WY' }
  ]

  const updateNewLocation = (element) => {
      setNewLocation(prevLocation => {
          let updatedLocation = prevLocation
          updatedLocation[element.target.name] = element.target.value
          return updatedLocation
      })
  }

  const handleNewLocationEntry = () => {

      let newLoc = newLocation
      let existingLocs = locations

      if (hasRequiredLocationFields(newLoc)) {
        let removedNewLocs = existingLocs.filter( location => 
            !Boolean(location.IsNew)
        )
  
        removedNewLocs.push(newLoc)
  
        setLocations(prevLocations => 
          removedNewLocs
        )
  
        setSelectedLocation(prevLocation =>
            newLoc['LocNum'] 
        )
  
        resetNewLocation();
        handleClickClose();
        setNewLocFormHasErrors(false);

      }
      else
      {
          setNewLocFormHasErrors(true)
      }
  }

  const hasRequiredLocationFields = (location) => {
      return (
          (location.hasOwnProperty('Addr1') && location['Addr1']) &&
          (location.hasOwnProperty('AddrName') && location['AddrName']) &&
          (location.hasOwnProperty('CityName') && location['CityName']) &&
          (location.hasOwnProperty('StateCode') && location['StateCode']) &&
          (location.hasOwnProperty('PostalCode') && location['PostalCode']) &&
          (location.hasOwnProperty('CountryISO2') && location['CountryISO2'])
      )    
  }

  const resetNewLocation = () => {
    setNewLocation(initialNewLocation)
  }

  return (
    <div className="freightBillHeader__wrapper mt-10">
      <div className="flex-col flex1">
        <div className="flex-row flex-gap-10">
          <FormControl variant="standard" fullWidth className="mb-10 flex1">
              <InputLabel htmlFor="input-with-icon-adornment">
                  Freight Bill #
              </InputLabel>
              <Input name="freightBill[referenceNums]" id="freightBillNumber" required={ true } />
          </FormControl>
          <FormControl variant="standard" fullWidth className="flex1">
              <InputLabel id="freightBillTypeLabel">
                Type
              </InputLabel>
              <Select
                labelId="freightBillTypeLabel"
                id="freightBillType"
                label="Freight Bill Type"
                required={ true }
                name="freightBill[freightBillType]"
                defaultValue="Original"
              >
                <MenuItem value="Original">Original</MenuItem>
                <MenuItem value="Supplemental">Supplemental</MenuItem>
              </Select>
            </FormControl>
          </div>
        <input type="hidden" name="freightBill[type]" required defaultValue="FreightBill" />
        <PageSelector name="freightBill[pages]" id="freightBillPages" required={ true } pageCount={ props.pageCount } />
      </div>
      <FormControl variant="standard" fullWidth className="flex1 align-end">
          <InputLabel id="freightBillRemittanceLocationLabel">
            Remittance Location &nbsp;&nbsp;
            <Button variant="text" onClick={handleClickOpen}>Add New</Button>
          </InputLabel>

          <Dialog open={open} onClose={handleClickClose}>
            <DialogTitle>New Remittance Location</DialogTitle>
              <DialogContent>
                <DialogContentText sx={{ mb:2 }} className={ newLocFormHasErrors && ( 'errors' )}>Please fill in all required fields</DialogContentText>
                  <FormControl variant="standard" fullWidth required>
                    <InputLabel>Address Name</InputLabel>
                    <Input sx={{mt:2, mb:2}} name="AddrName" id="newLoc_AddrName" autoFocus margin="dense" variant="standard" label="Address Name" size="small" required onChange={updateNewLocation}/>
                  </FormControl>
                  <FormControl variant="standard" fullWidth required>
                    <InputLabel>Address Line 1</InputLabel>
                    <Input sx={{mb:2}} name="Addr1" id="newLoc__Addr1" variant="standard" label="Address Line 1" size="small" required={ true } onChange={updateNewLocation} />
                  </FormControl>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel>Address Line 2</InputLabel>
                    <Input sx={{mb:2}} name="Addr2" variant="standard" label="Address Line 2" size="small" onChange={updateNewLocation} />
                  </FormControl>
                  <FormControl variant="standard" fullWidth>
                    <InputLabel>Address Line 3</InputLabel>
                    <Input sx={{mb:2}} name="Addr3" variant="standard" label="Address Line 3" size="small" onChange={updateNewLocation} />
                  </FormControl>
                  <div className="flex-row flex-gap-10">
                    <FormControl variant="standard" required>
                      <InputLabel>City</InputLabel>
                      <Input sx={{mb:2}} name="CityName" variant="standard" label="City" size="small" required onChange={updateNewLocation} />
                    </FormControl>
                    <FormControl variant="standard" required={ true }>
                      <InputLabel id="newRLocState">
                      State
                      </InputLabel>
                      <Select name="StateCode" label="State" style={{ width: '100px'}} labelId="newRLocState" size="small" required={true} onChange={updateNewLocation}>
                        <MenuItem value=""></MenuItem>
                        { usStates.map( state => {
                          return (
                            <MenuItem value={ state.abbreviation }>{ state.abbreviation }</MenuItem>
                          )
                        })}
                      </Select>
                    </FormControl>
                    <FormControl variant="standard" style={{ width: '140px' }} required> 
                      <InputLabel>Postal</InputLabel>
                      <Input sx={{mb:2}} name="PostalCode" variant="standard" label="Postal" size="small" required onChange={updateNewLocation} />
                    </FormControl>
                    <FormControl variant="standard" style={{ width: '100px' }} required>
                      <InputLabel>Country</InputLabel>
                      <Input sx={{mb:2}} name="CountryISO2" variant="standard" label="Country" size="small" defaultValue="US" required onChange={updateNewLocation} />
                    </FormControl>
                  </div>
              </DialogContent>
              <DialogActions sx={{ mb: 1}}>
                <Button onClick={handleClickClose}>Cancel</Button>
                <Button onClick={handleNewLocationEntry} variant="contained" disableElevation>Save Location</Button>
              </DialogActions>
          </Dialog>

          <Select
              labelId="freightBillRemittanceLocationLabel"
              id="freightBillRemittanceLocation"
              label="Remittance Location"
              required={ true }
              name="freightBill[remittanceLocation][code]"
              value={ selectedLocation }
              onChange={ handleLocationChange }
          >
              { locations.map( location => {
                  return (
                      <MenuItem value={ location.LocNum } key={ location.LocNum }>
                        <small className="small__orgName block">{ location.OrganizationName }</small>
                        { location.AddrName } <br/> 
                        { location.Addr1 } <br/> 
                        { location.Addr2.length === 0 
                          ? ""
                          : `${location.Addr2} <br/>` 
                        }
                        { location.CityName }, { location.StateCode } { location.PostalCode }
                      </MenuItem>
                  )
              })}
          </Select>
      </FormControl>
      <input type="hidden" name="freightBill[remittanceLocation][details]" value={ JSON.stringify(locations) } />
    </div>
  );
};

export default FreightBillHeader;
