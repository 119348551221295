import React from 'react';
import './Content.css';
import FileLoader from '../FileLoader/FileLoader';
import Load from '../Load/Load'
import File from '../File/File';

const Content = () => {

    const [fileLoaded, setFileLoaded] = React.useState(false)
    const [filepath, setFilepath]     = React.useState(null)
    const [pageCount, setPageCount]   = React.useState(0)

    const handleFileUpload = (path, pageCount) => {
        setFilepath(path)
        setPageCount(pageCount)
        setFileLoaded(true)
    }

    return (
        <div className="content__wrapper">
            {fileLoaded && (
                <div className="content__wrapper-loaded">
                    <Load pageCount={ pageCount } filepath={ filepath } />
                    <File src={ filepath } />
                </div>
            )}
            {!fileLoaded && (
                <FileLoader handleSuccessfulUpload={ handleFileUpload } />
            )}
        </div>
    );

};

export default Content;
