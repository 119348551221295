import React, {useContext} from 'react';
import LoadSearcher from '../LoadSearcher/LoadSearcher'
import LoadDetails from '../LoadDetails/LoadDetails'
import CarrierSelect from '../CarrierSelect/CarrierSelect'
import LoadDocument from '../LoadDocument/LoadDocument'
import LoadDocumentAlerts from '../LoadDocumentAlerts/LoadDocumentAlerts'
import FreightBillHeader from '../FreightBill/FreightBillHeader'
import FreightBillCharge from '../FreightBill/FreightBillCharge'
import ExistingDocuments from '../LoadDocument/ExistingDocuments'
import { FormControl, FormControlLabel, Input, InputLabel, Switch, Alert, Button, Backdrop, CircularProgress } from '@mui/material'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faLock, faTimesCircle, faCheckCircle, faCheck, faTimes } from '@fortawesome/free-solid-svg-icons'
import {UserContext} from '../../context/UserContext';
import axios from 'axios'
import './Load.css'

const Load = (props) => {

    const {user} = useContext(UserContext);

    // Step one - retrieve load
    const [loadNum, setLoadNum] = React.useState(null)
    const [isValidLoad, setIsValidLoad] = React.useState(false)
    const [loadData, setLoadData] = React.useState({})
    const [loadCosts, setLoadCosts] = React.useState(null)
    const [loadDetails, setLoadDetails] = React.useState(null)
    const [mode, setMode] = React.useState(null)
    const [startDate, setStartDate] = React.useState(null)

    // Step two - select payable carrier
    const [selectedCarrier, setSelectedCarrier] = React.useState(null)
    const [carrierSelector, setCarrierSelector] = React.useState(null)
    const [carrierName, setCarrierName] = React.useState(null)

    // Step two - freight bill
    const [hasFreightBill, selectHasFreightBill] = React.useState(false)
    const [freightBillHeader, setFreightBillHeader] = React.useState(null)
    const [freightBillCharges, setFreightBillCharges] = React.useState([])
    const [freightBillChargesCount, setFreightBillChargesCount] = React.useState(0)
    const [freightBillCount, setFreightBillCount] = React.useState(0)

    // Step three - load documents
    const [hasLoadDocuments, setHasLoadDocuments] = React.useState(false)
    const [loadDocuments, setLoadDocuments] = React.useState([])
    const [loadDocumentsCount, setLoadDocumentsCount] = React.useState(0)
    const [loadDocumentAlerts, setLoadDocumentAlerts] = React.useState([])

    // Final step - sending the form
    const [formIsProcessing, setFormIsProcessing] = React.useState(false)
    const [formResponse, setFormResponse] = React.useState({})


    // Step one functions
    const handleLoadNumberChange = (newLoadNum, newIsValidLoad, newLoadData) => {

        setLoadNum(newLoadNum)
        setIsValidLoad(newIsValidLoad)
        setSelectedCarrier(null)
        setLoadData(newLoadData)
        setCarrierSelector(null)
        setLoadDetails(null)
        setHasLoadDocuments(false)
        setLoadDocuments([])
        setLoadCosts(null)

        if (!newIsValidLoad) return

        setCarrierSelector(<CarrierSelect handleChange={ handleSelectedCarrierChange } carriers={ newLoadData.carriers } loadData={ newLoadData } />)
        setLoadDetails(<LoadDetails loadData={ newLoadData } />)
        setMode(newLoadData.mode)
        setStartDate(newLoadData.loadDetails.dates.planned_start)
        setLoadDocumentAlerts(<LoadDocumentAlerts alerts={ newLoadData.documentAlerts } />)

        let newFreightBillCount = 0
        newLoadData.documents.map( document => {
            if (document.DocType === 'Freight Bill'){
                newFreightBillCount += 1
            }
        })

        setFreightBillCount(newFreightBillCount)

    }

    const __field__loadNum = <LoadSearcher handleChange={ handleLoadNumberChange } />

    // Step two functions
    const handleSelectedCarrierChange = (newSelectedCarrier = null, loadCosts = [], carrierLocations = [], isPrimary = false, loadData = [], carrNm = null) => {

        selectHasFreightBill(false)
        setHasLoadDocuments(false)
        setLoadCosts(JSON.stringify(loadCosts))

        setSelectedCarrier( newSelectedCarrier )
        setCarrierName( carrNm )
        setFreightBillHeader(<FreightBillHeader locations={ carrierLocations } pageCount={ props.pageCount } />)
        setFreightBillChargesCount(-1)

        setLoadData(loadData)

        let newCharges = [];
        let chargeCount = -1;

        if (loadCosts){
            newCharges = loadCosts.map( cost => {
                chargeCount += 1
                return <FreightBillCharge costData={ cost } id={ chargeCount } key={ chargeCount } delete={ handleDeleteFreightBillCharge } />
            })
            setFreightBillChargesCount(chargeCount);
        }

        if (isPrimary ){
           // check each required document, and create the load document
           if (loadData.requiredDocuments.length > 0){

                // else, check if the document is missing

               let newLoadDocs = loadData.requiredDocuments.map((requiredDocument, id) => {

                    let received = false

                    // check if the document is already received
                    loadData.documents.map ( document => {
                        if (document.DocType === requiredDocument.type && document.DocNum.search(requiredDocument.orderNum) > 0 ){
                            received = true;
                            return 
                        }
                    })

                    return <LoadDocument 
                                key={ id } 
                                id={ id } 
                                docType={ requiredDocument.type } 
                                stopNum={ requiredDocument.stopNum } 
                                accCode={ null } 
                                loadData={ loadData } 
                                pageCount={ props.pageCount } 
                                orders={ requiredDocument.orderNum } 
                                missingRefValue={ requiredDocument.missingRef } 
                                isRequired={ true } 
                                isReceived={ received }
                                isMissing={ requiredDocument.isMissing }
                                delete={ handleDeleteLoadDocument }
                    />
               })

               setLoadDocumentsCount(newLoadDocs.length)
               setLoadDocuments(newLoadDocs)
               setHasLoadDocuments(true)
           }
        }

        setFreightBillCharges(newCharges)

    }

    // ------------------------------------------------------------------------------------------------------
    // Create fb functions
    // ------------------------------------------------------------------------------------------------------

    const handleHasFreightBillChange = () => {
        selectHasFreightBill( prevState => !prevState )
    }

    const handleAddFreightBillCharge = () => {
        setFreightBillChargesCount(prevCount => prevCount + 1)
        let newFreightBillCharge = <FreightBillCharge costData={[]} id={ freightBillChargesCount + 1 } key={ freightBillChargesCount + 1 } delete={ handleDeleteFreightBillCharge } />
        setFreightBillCharges(prevFreightBillCharges => [
            ...prevFreightBillCharges, newFreightBillCharge
        ])
    }

    const handleDeleteFreightBillCharge = (id) => {
        setFreightBillCharges(
            prevFreightBillCharges => prevFreightBillCharges.filter(
                freightBillCharge => freightBillCharge.props.id !== id
            )
        )
    }

    // ------------------------------------------------------------------------------------------------------
    // Step four - process load documents
    // ------------------------------------------------------------------------------------------------------

    const handleHasLoadDocumentsChange = () => {
        setHasLoadDocuments( prevState => !prevState)
    }

    const handleAddLoadDocument = () => {
        setLoadDocumentsCount(prevCount => prevCount + 1)
        let newLoadDocument = (
            <LoadDocument 
                key={ loadDocumentsCount } 
                id={ loadDocumentsCount } 
                docType={ null }
                stopNum={ null }
                accCode={ null }
                loadData={ loadData } 
                pageCount={ props.pageCount } 
                orders={ null } 
                isRequired={ false } 
                isReceived={ false } 
                isMissing={ false}
                missingRefValue={ null } 
                delete={ handleDeleteLoadDocument } 
            />
        )
        setLoadDocuments(prevLoadDocuments => [
            ...prevLoadDocuments, newLoadDocument
        ])
    }

    const handleDeleteLoadDocument = (id) => {

        console.log(loadDocuments)

        setLoadDocuments(
            prevLoadDocuments => prevLoadDocuments.filter(
                loadDocument => loadDocument.props.id !== id
            )
        )
    }

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setFormResponse({})
        setFormIsProcessing(true);

        var formData = new FormData(document.querySelector('form'))
        const response = await axios.post('https://api.trademarktrans.com/request.php', formData );
        //const response = await axios.post('http://localhost:7777/request.php', formData );
        
        setFormResponse(response.data)

    }

    const refreshPage = () => {
        window.location.assign("index.html");
    }

    // Render the main UI
    return (
        <div className="load__wrapper">

            <form onSubmit={ handleFormSubmit }>

                <input type="hidden" name="filepath" defaultValue={ props.filepath } />
                <input type="hidden" name="mode" defaultValue={ mode } />
                <input type="hidden" name="action" defaultValue="splitPaperwork" required />
                <input type="hidden" name="user" defaultValue={ user.email } />
                <input type="hidden" name="loadCosts" defaultValue={ loadCosts } />
                <input type="hidden" name="carrierName" defaultValue={ carrierName } />
                <input type="hidden" name="startDate" defaultValue={ startDate } />

                <Button variant="outlined" fullWidth disableElevation={ true } onClick={ refreshPage } className="mb-10">Start Over</Button>

                <div className="load__form--group">

                    <div>

                        <div>
                        <FormControl variant="standard" sx={{ minWidth: 300, mb: "10px" }}>
                            <InputLabel htmlFor="input-with-icon-adornment">
                                Date Received
                            </InputLabel>
                            <Input type="date" defaultValue={ new Date().toISOString().substring(0, 10) } name="dateReceived" id="dateReceived" />
                        </FormControl>
                        </div>

                        <div>
                        { __field__loadNum }
                        </div>

                        { isValidLoad && (
                            <div>
                                { carrierSelector }
                            </div>
                        )}
                    </div>
                    { isValidLoad && (
                        <div className="loadDetails__details">
                            { loadDetails } 
                        </div>
                    )}
                </div>
                { selectedCarrier && (
                    <div>
                        <hr className="load__form--hr" />
                        <div className="load__form--section">
                            <div className="load__form--section-title--toggler">
                                <FormControlLabel control={<Switch name="freightBill[processFreightBill]" defaultValue="true" onChange={ handleHasFreightBillChange } checked={ hasFreightBill } />} label={<strong>Freight Bill</strong>} />
                            </div>
                            { hasFreightBill && (
                                <div className="load__form--section-content">

                                    { freightBillCount > 0 && (
                                        <Alert severity="warning">There are <strong>{freightBillCount}</strong> existing Freight Bill(s) in TMS.</Alert>
                                    )}

                                    { freightBillHeader }
                                    <table className="load__form--table mt-20">
                                        <thead>
                                            <tr>
                                                <th>Charge Type</th>
                                                <th>Accessorial</th>
                                                <th>Details</th>
                                                <th>Amount</th>
                                                <th className="text-center">
                                                    <FontAwesomeIcon icon={ faPlusCircle } onClick={ handleAddFreightBillCharge } />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            { freightBillCharges.map( freightBillCharge => {
                                                return freightBillCharge
                                            })}
                                        </tbody>
                                    </table>

                                </div>
                            )}
                        </div>
                        <div className="load__form--section">
                            <div className="load__form--section-title--toggler">
                                <FormControlLabel control={<Switch name="loadDocuments[processLoadDocuments]" defaultValue="true" onChange={ handleHasLoadDocumentsChange } checked={ hasLoadDocuments } />} label={<strong>Load Documents</strong>} />
                            </div>
                            { hasLoadDocuments && (
                                <div className="load__form--section-content">

                                    { loadDocumentAlerts }

                                    <table className="load__form--table load__form--table-docs">
                                        <thead>
                                            <tr>
                                                <th className="text-center"><FontAwesomeIcon icon={ faLock } title="Required" /></th>
                                                <th className="text-center"><FontAwesomeIcon icon={ faTimesCircle } title="Missing" /></th>
                                                <th className="text-center"><FontAwesomeIcon icon={ faCheckCircle } title="Received" /></th>
                                                <th>Type</th>
                                                <th>Accessorial</th>
                                                <th>Stop</th>
                                                <th>Order(s)</th>
                                                <th>Page(s)</th>
                                                <th className="text-center pointer">
                                                    <FontAwesomeIcon icon={ faPlusCircle } className="pointer" onClick={ handleAddLoadDocument } />
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>

                                            { loadDocuments.length > 0 ? (
                                                loadDocuments.map ( loadDocument => {
                                                    return loadDocument
                                                })
                                            ) : (
                                                <tr>
                                                    <td colSpan="100%" className="text-center">No required documents</td>
                                                </tr>
                                            ) }

                                        </tbody>
                                    </table>

                                    { loadData.documents && (
                                        <ExistingDocuments documents={ loadData.documents } />
                                    )}

                                </div>
                            )}
                        </div>

                        { !formIsProcessing && (
                            <Button 
                                variant="contained" 
                                className="mtb-30" 
                                disableElevation={ true } 
                                fullWidth 
                                type="submit"
                                disabled={ formIsProcessing } 
                            >
                            Process
                            </Button>
                        )}


                    </div>
                )}
            </form>

            { formIsProcessing && (
                <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} open={ true } >
                    <div className='split-output'>
                        { Object.keys(formResponse).length === 0 ? 
                            <CircularProgress size={ 25 }></CircularProgress> :
                            <div>

                                { console.log(formResponse) }

                                { formResponse.successful ? (
                                    <h2 className="p-0">Success!</h2>
                                ) : (
                                    <h2 className="p-0">Error!</h2>
                                ) } 

                                { formResponse.errors && (
                                    formResponse.errors.map( error => {
                                        return (<Alert severity="error">{ error }</Alert>)
                                    })
                                )}

                                <table className="mt-10">
                                    <thead>
                                        <tr>
                                            <th className="text-left">Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { formResponse.outputActions.actions.map( action => {
                                            return (
                                                <tr>
                                                    <td>{ action }</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                                <table className="mt-10">
                                    <thead>
                                        <tr>
                                            <th className="text-left">Uploads</th>
                                        </tr>
                                        <tr>
                                            <th className="text-left">Type</th>
                                            <th className="text-left">Filename</th>
                                            <th className="text-center">3G</th>
                                            <th className="text-center">S3</th>
                                            <th className="text-center">TDMK</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { formResponse.outputActions.uploads.map( upload => {
                                            return (
                                                <tr>
                                                    <td>{ upload.document.type }</td>
                                                    <td>{ upload.document.basename }</td>
                                                    <td className="text-center">{ upload.attachedTo3G === true ? <FontAwesomeIcon icon={ faCheck } /> : <FontAwesomeIcon icon={ faTimes } /> }</td>
                                                    <td className="text-center">{ upload.attachedToS3 === true ? <FontAwesomeIcon icon={ faCheck } /> : <FontAwesomeIcon icon={ faTimes } /> }</td>
                                                    <td className="text-center">{ upload.attachedToFTP === true ? <FontAwesomeIcon icon={ faCheck } /> : <FontAwesomeIcon icon={ faTimes } /> }</td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>

                                <Button className="mt-20" variant="contained" disableElevation={true} fullWidth onClick={ refreshPage }>Split Again</Button>

                            </div>
                        }
                    </div>
                </Backdrop>
            )}

        </div>
    );
};

export default Load;