import React from 'react';
import Navbar from '../../components/Navbar/Navbar';
import './History.css'
import axios from 'axios'
import { CircularProgress } from '@mui/material'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const History = () => {

    const [hasData, setHasData] = React.useState(false)
    const [logData, setLogData] = React.useState({})

    React.useEffect(() => {
        getLogData();
    }, []);

    //const requestURL = 'http://tdmkapi.us-east-2.elasticbeanstalk.com/request.php'
    const requestURL = 'https://api.trademarktrans.com/request.php'

    const getLogData = async () => {
        const response = await axios.get(requestURL, { 
            params: {
                'action': 'getLogs'
            }
        })
        setLogData(response.data)
        setHasData(true)
        console.log(response.data)
    }

    return (
        <div className="app__wrapper">
            <Navbar />
            <div className="content__wrapper">
                <h1 className="p-0">Splitter History</h1>
                <div className="mb-10">
                    { !hasData ? (
                        <CircularProgress size={ 35 } />
                    ) : (
                        <table className="mt-10 mb-10 history__table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Load Number</th>
                                    <th>User</th>
                                    <th>Date Split</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                { logData.map( record => {

                                    return (
                                        <>
                                            <tr key={ record.uuid } rowSpan={ 2 }>
                                                <td className="text-center pointer narrow">
                                                    <FontAwesomeIcon icon={ faChevronRight } />
                                                </td>
                                                <td>{ record.load_number }</td>
                                                <td>{ record.username }</td>
                                                <td>{ record.date_split }</td>
                                                <td>
                                                    <ul>
                                                        { JSON.parse(record.output_actions)['actions'].map( action => {
                                                            return <li>{action}</li>
                                                        })}
                                                    </ul>
                                                </td>
                                            </tr>
                                        </>
                                    )
                                })}
                            </tbody>
                        </table>
                    ) }
                </div>
            </div>
        </div>
    )
}

/*
    <tr>
        <td colSpan={4}>
            <pre className="pre__text">{ JSON.stringify(JSON.parse(record.output_actions), null, 5) }</pre>
        </td>
    </tr>
*/

export default History