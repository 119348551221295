import {React, useContext} from 'react';
import tdmkLogo from '../../assets/img/TM_Logo_Blue.gif';
import './Navbar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHistory, faSignOutAlt, faCopy } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'
import {UserContext} from '../../context/UserContext';

const Navbar = () => {

  const {user, logout} = useContext(UserContext);

  const refreshPage = () => {
    window.location.assign("index.html");
  }


  return (
    <nav className="nav">
        <img src={tdmkLogo} alt="Trademark" className="nav__img" />
        <ul className="nav__links">
            <li className="nav__link" >
              <a href="#" onClick={ refreshPage }>
                <FontAwesomeIcon icon={ faCopy } />
                Splitter
              </a>
            </li>
            <li className="nav__link">
                <Link to="/history">
                  <FontAwesomeIcon icon={ faHistory } />
                  History
                </Link>
            </li>
            <li className="nav__link">
                <a href="#" onClick={ logout }>
                  <FontAwesomeIcon icon={ faSignOutAlt } />
                  Logout
                </a>
            </li>
        </ul>   
    </nav>
  );
};

export default Navbar;
