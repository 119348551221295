import Navbar from '../../components/Navbar/Navbar';
import Content from '../../components/Content/Content';
import {UserContext} from '../../context/UserContext';
import {React, useContext} from 'react';

const Main = () => {

    const {user, logout} = useContext(UserContext);

      return (
          <div className="app__wrapper">
            <Navbar />
            <Content />
          </div>
      );
};

export default Main;