import React from 'react';
import './LoadDetails.css'

const LoadDetails = (props) => {

    const stopsString = getStopsString();
    const totalStops  = (props.loadData.totalStops) ? props.loadData.totalStops : 'unknown';
    const totalOrders = (props.loadData.orderCount) ? props.loadData.orderCount : 'unknown';
    const datesString = (props.loadData.loadDetails.dates.planned_start && props.loadData.loadDetails.dates.planned_end) ? 
        props.loadData.loadDetails.dates.planned_start + ' - ' + props.loadData.loadDetails.dates.planned_end : 'unknown';

    function getStopsString(){
        return (props.loadData.stopSummary) ? props.loadData.stopSummary : '';
    }

    return (
        <span>
            <ul className="load__form--ul">
                <li><strong>Planned Start/End: </strong>{datesString}</li>
                <li><strong>Stop Summary</strong>: {stopsString}</li>
                <li><strong>Total Stops: </strong>{totalStops}</li>
                <li><strong>Total Orders: </strong>{totalOrders}</li>
                <li><strong>Mode: </strong>{props.loadData.mode}</li>
            </ul>
        </span>
    );
};

export default LoadDetails;
