import React from 'react'
import LoadDocumentAlert from './LoadDocumentAlert'


const LoadDocumentAlerts = (props) => {

    const alerts = props.alerts
    console.log(alerts);

  return (
    <>
        { alerts.length > 0 && (
            <div>
            { alerts.map ( alert => {
                return <LoadDocumentAlert message={ alert } />
            })}
            </div>
        )}
    </>
  )
}

export default LoadDocumentAlerts
