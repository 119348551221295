import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import PageSelector from '../PageSelector/PageSelector'
import { FormControl, Select, MenuItem, Checkbox, ListItemText, Input } from '@mui/material'

const LoadDocument = (props) => {

    const initialOrders = props.stopNum ? props.loadData.stops[props.stopNum].orders : []

    const [stopOrders, setStopOrders] = React.useState(initialOrders)
    const [selectedOrders, setSelectedOrders] = React.useState([props.orders])
    const [isMissing, setIsMissing] = React.useState(props.isMissing)

    const [docType, setDocType] = React.useState(props.docType)
    const [accCode, setAccCode] = React.useState(props.accCode)

    const handleStopChange = (e) => {
        let stopNum = e.target.value
        if (!stopNum) return
        let orders = props.loadData.stops[stopNum].orders
        setStopOrders(orders)
    }

    const handleSelectedOrderChange = (event) => {
        const {
            target: { value },
        } = event;
        setSelectedOrders(
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    const handleMissingChange = () => {
        setIsMissing(prevState => !prevState)
    }

    const handleDocumentTypeChange = (e) => {
        var selectedDocType = e.target.value
        setDocType(selectedDocType)
        if (selectedDocType !== 'LumperReceipt'){
            setAccCode(null)
        } else {
            console.log("changing to LUM!")
            setAccCode('LUM')
        }
    }

    const handleAccCodeChange = (e) => {
        var selectedAccCode = e.target.value
        setAccCode(selectedAccCode)
    }

  return (

    <>

    { props.isReceived ? (
        
            <tr className="loadDocument__received-row" key={ props.id }>
                <td className="text-center">
                    <input type="checkbox" defaultChecked={ props.isRequired } readOnly disabled />
                </td>
                <td className="text-center">
                    <input type="radio" defaultChecked={ false } readOnly disabled />
                </td>
                <td className="text-center">
                    <input type="radio" defaultChecked={ true } readOnly disabled />
                </td>
                <td className="text-center">
                    { props.docType }
                </td>
                <td>
                    <select className="load__form--table-input select__disabled" defaultValue={ props.accCode } disabled readOnly>
                        <option value=""></option>
                        <option value='SD100'>$100 Special Delivery Charge</option>
                        <option value='SDC50'>$50 Special Delivery Charge</option>
                        <option value='FSCA'>Additional Fuel Surcharge</option>
                        <option value='ADHS'>Additional Handling</option>
                        <option value='PMT'>After Hours Service</option>
                        <option value='ARB'>Arbitrary Charge</option>
                        <option value='BCF'>Border Crossing Fee</option>
                        <option value='BLK'>Bulkhead Charge</option>
                        <option value='CAP'>Capacity Surcharge</option>
                        <option value='EBD'>Convention Delivery</option>
                        <option value='EBP'>Convention Pickup</option>
                        <option value='CDC'>Crossdock Charge</option>
                        <option value='DED'>Dead Head</option>
                        <option value='DASAK'>Delivery Surcharge ? AK</option>
                        <option value='DASCONUS'>Delivery Surcharge ? Cont. US</option>
                        <option value='DASHI'>Delivery Surcharge ? HI</option>
                        <option value='DET'>Detention</option>
                        <option value='HAN'>Driver Unloading Charges</option>
                        <option value='EXM'>Excess Mileage</option>
                        <option value='DASEXCONUS'>Extended Surcharge ? Cont. US</option>
                        <option value='FSC'>Fuel Surcharge</option>
                        <option value='HAZ'>Hazardous Materials Charge</option>
                        <option value='HCS'>High Cost Service Area</option>
                        <option value='HRS'>Hourly Service</option>
                        <option value='011'>Inbound Charge</option>
                        <option value='INSD'>Inside Delivery</option>
                        <option value='INSP'>Inside Pickup Charge</option>
                        <option value='DASINTRAHI'>Intra HI Surcharge</option>
                        <option value='LAT'>Late Fee</option>
                        <option value='LAY'>Layover Fee</option>
                        <option value='LFT'>Lift Gate Charge</option>
                        <option value='LUM'>Lumper Service</option>
                        <option value='JKCM'>Money Code Transfer</option>
                        <option value='OVERSIZE'>Oversize</option>
                        <option value='PLT'>Pallet Charge</option>
                        <option value='PDS'>Peak Demand Surcharge</option>
                        <option value='PERM'>Permits &amp; Escorts</option>
                        <option value='REC'>Reconsignment Charge</option>
                        <option value='RED'>Redelivery Charge</option>
                        <option value='REM'>Remote Access Fee</option>
                        <option value='RSC'>Reschedule Fee</option>
                        <option value='RES'>Residential Surcharge</option>
                        <option value='RET'>Return Charge</option>
                        <option value='SAT'>Saturday Service</option>
                        <option value='SSG'>Sort &amp; Segregate Charge</option>
                        <option value='STOP'>Stop Charge</option>
                        <option value='STR'>Storage Fees</option>
                        <option value='SCBL'>Summit Cold BOL Charge</option>
                        <option value='SCDC'>Summit Cold Crossdock Charge</option>
                        <option value='SCPC'>Summit Cold Placard Charge</option>
                        <option value='TEM'>Team Driver</option>
                        <option value='TONU'>Truck Ordered Not Used</option>
                        <option value='WHS'>Warehousing Fee</option>
                        <option value='WI'>Weight Inspection</option>
                    </select>
                </td>
                <td className="">
                    { props.loadData.stops[props.stopNum].stopNum }. { props.loadData.stops[props.stopNum].stopType } - { props.loadData.stops[props.stopNum].locDetails.AddrName } { props.loadData.stops[props.stopNum].locDetails.CityName }, { props.loadData.stops[props.stopNum].locDetails.StateCode }
                </td>
                <td className="text-center">
                    {props.orders}
                </td>
                <td></td>
                <td className="text-center text-red"></td>
            </tr>
    ) : (

        <tr key={ props.id }>
            <td className="text-center">
                <input type="checkbox" readOnly defaultChecked={ props.isRequired } name={ "documents[" + props.id + "][required]" } value="true" />
            </td>
            <td className="text-center">
                <input type="radio" name={ "documents[" + props.id + "][received]" } value="false" defaultChecked={ props.isMissing } onChange={ handleMissingChange } required />
                <input type="hidden" name={ "documents[" + props.id + "][missingRef]" } value={ props.missingRefValue } />
            </td>
            <td className="text-center">
                <input type="radio" name={ "documents[" + props.id + "][received]" } value="true" required defaultChecked={ props.isReceived } />
            </td>
            <td>
                <select name={ "documents[" + props.id + "][type]" } className="load__form--table-input" defaultValue={ docType } value={ docType || ''} required onChange={ handleDocumentTypeChange }>
                    <option value=""></option>
                    <option value="POD">POD</option>
                    <option value="BOL">BOL</option>
                    <option value="LumperReceipt">LumperReceipt</option>
                    <option value="AccessorialReceipt">AccReceipt</option>
                </select>
            </td>
            <td>
                <select name={ "documents[" + props.id + "][accCode]" } className="load__form--table-input" defaultValue={ accCode } value={ accCode || ''} onChange={ handleAccCodeChange }>
                    <option value=""></option>
                    <option value='SD100'>$100 Special Delivery Charge</option>
                    <option value='SDC50'>$50 Special Delivery Charge</option>
                    <option value='FSCA'>Additional Fuel Surcharge</option>
                    <option value='ADHS'>Additional Handling</option>
                    <option value='PMT'>After Hours Service</option>
                    <option value='ARB'>Arbitrary Charge</option>
                    <option value='BCF'>Border Crossing Fee</option>
                    <option value='BLK'>Bulkhead Charge</option>
                    <option value='CAP'>Capacity Surcharge</option>
                    <option value='EBD'>Convention Delivery</option>
                    <option value='EBP'>Convention Pickup</option>
                    <option value='CDC'>Crossdock Charge</option>
                    <option value='DED'>Dead Head</option>
                    <option value='DASAK'>Delivery Surcharge ? AK</option>
                    <option value='DASCONUS'>Delivery Surcharge ? Cont. US</option>
                    <option value='DASHI'>Delivery Surcharge ? HI</option>
                    <option value='DET'>Detention</option>
                    <option value='HAN'>Driver Unloading Charges</option>
                    <option value='EXM'>Excess Mileage</option>
                    <option value='DASEXCONUS'>Extended Surcharge ? Cont. US</option>
                    <option value='FSC'>Fuel Surcharge</option>
                    <option value='HAZ'>Hazardous Materials Charge</option>
                    <option value='HCS'>High Cost Service Area</option>
                    <option value='HRS'>Hourly Service</option>
                    <option value='11'>Inbound Charge</option>
                    <option value='INSD'>Inside Delivery</option>
                    <option value='INSP'>Inside Pickup Charge</option>
                    <option value='DASINTRAHI'>Intra HI Surcharge</option>
                    <option value='LAT'>Late Fee</option>
                    <option value='LAY'>Layover Fee</option>
                    <option value='LFT'>Lift Gate Charge</option>
                    <option value='LUM'>Lumper Service</option>
                    <option value='JKCM'>Money Code Transfer</option>
                    <option value='OVERSIZE'>Oversize</option>
                    <option value='PLT'>Pallet Charge</option>
                    <option value='PDS'>Peak Demand Surcharge</option>
                    <option value='PERM'>Permits &amp; Escorts</option>
                    <option value='REC'>Reconsignment Charge</option>
                    <option value='RED'>Redelivery Charge</option>
                    <option value='REM'>Remote Access Fee</option>
                    <option value='RES'>Residential Surcharge</option>
                    <option value='RET'>Return Charge</option>
                    <option value='SAT'>Saturday Service</option>
                    <option value='SSG'>Sort &amp; Segregate Charge</option>
                    <option value='STOP'>Stop Charge</option>
                    <option value='STR'>Storage Fees</option>
                    <option value='SCBL'>Summit Cold BOL Charge</option>
                    <option value='SCDC'>Summit Cold Crossdock Charge</option>
                    <option value='SCPC'>Summit Cold Placard Charge</option>
                    <option value='TEM'>Team Driver</option>
                    <option value='TONU'>Truck Ordered Not Used</option>
                    <option value='WHS'>Warehousing Fee</option>
                    <option value='WI'>Weight Inspection</option>
                </select>
            </td>
            <td>
                <select name={ "documents[" + props.id + "][stop]" } className="load__form--table-input" required onChange={ handleStopChange } defaultValue={ props.stopNum }>
                    <option value=""></option>
                    { Object.keys(props.loadData.stops).map( stop => {
                        return <option key={ props.loadData.stops[stop].stopNum } value={ props.loadData.stops[stop].stopNum }>{ props.loadData.stops[stop].stopNum }. { props.loadData.stops[stop].stopType } - { props.loadData.stops[stop].locDetails.AddrName } { props.loadData.stops[stop].locDetails.CityName }, { props.loadData.stops[stop].locDetails.StateCode }</option> 
                    })}
                </select>
            </td>
            <td>
                <FormControl variant="standard" sx={{ width: 150, mt: 0 }}>
                    <Select
                        id={"documents[" + props.id + "][referenceNums]"}
                        multiple
                        required={ true }
                        name={"documents[" + props.id + "][referenceNums]"}
                        value={ selectedOrders }
                        onChange={ handleSelectedOrderChange }
                        input={<Input label="Tag" />}
                        renderValue={(selected) => selected.join(', ')}
                    >

                        { Object.keys(stopOrders).map( order => {

                            let description = (
                                <span>
                                    <p className="p-0"><strong>{order}</strong></p>
                                    <p className="p-0"><small>{stopOrders[order].client}</small></p>
                                    <p className="p-0"><small>{stopOrders[order].quantities.pcs} pieces, {parseInt(stopOrders[order].quantities.pos)} pallets, {parseInt(stopOrders[order].quantities.weight)} lbs</small></p>
                                    { stopOrders[order].refs && (
                                        <span className="p-0">
                                            <p className="p-0"><small className="refs__title">References</small></p>
                                            <ul className="ul__small">
                                                { stopOrders[order].refs.map( ref => {
                                                    return <li>{ref.Qualifier.QualifierName}: {ref.RefNumValue}</li>
                                                })}
                                            </ul>
                                        </span>
                                    )}
                                </span>
                            )

                            return <MenuItem value={order} key={order}>
                                <Checkbox checked={selectedOrders.indexOf(order) > -1} />
                                <ListItemText primary={description} />
                            </MenuItem>
                        })}

                    </Select>
                </FormControl>
            </td>
            <td>
                <PageSelector name={ "documents[" + props.id + "][pages]" } id={ props.id } pageCount={ props.pageCount } hideLabel={ true } required={ !isMissing } />
            </td>
            <td className="text-center text-red">
                
                <FontAwesomeIcon icon={ faTrash } onClick={ () => props.delete(props.id) } className="pointer" />
                
            </td>
        </tr>

    )}

    </>

  );
};

export default LoadDocument;
