import React from 'react'
import { Alert } from '@mui/material'


const LoadDocumentAlert = (props) => {

  const message = props.message

  return (
    <Alert severity="warning" sx={{ mb:'10px' }}>{ message }</Alert>
  )
}

export default LoadDocumentAlert